<template>
  <div class="social-icon-container">
    <ShareNetwork
      network="linkedin"
      :url="`${socialShareUrl}`"
      :title="title"
      :description="description"
      style="display: inline-block !important"
      class="aw-ssi"
      @open="registerSocialShare(id, title)"
      v-if="share_buttons && share_buttons.linkedin"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        height="23"
        class="ss-icon"
      >
        <circle fill="#f0f0f0" stroke-width="0" cx="11" cy="11" r="11" class="ss-color" />
        <path
          fill-rule="evenodd"
          fill="#575757"
          stroke-width="0"
          d="M7.62,6.13c-.64,0-1.13.48-1.15,1.1,0,.65.47,1.16,1.09,1.17.62,0,1.13-.49,1.13-1.13s-.48-1.14-1.08-1.16h0ZM11.56,15.38h-1.84v-6.11h1.76v.81s.11-.13.16-.19c.1-.12.18-.2.27-.29.77-.74,2.11-.56,2.68-.19.54.35.78.9.85,1.52.11.91.1,1.82.09,2.73,0,.58-.01,1.14,0,1.72h-1.83s-.06-.14-.04-.22v-2.8c0-.25,0-.48-.04-.72-.1-.56-.37-.84-.85-.87-.6-.03-.96.22-1.12.77-.1.33-.1.66-.1,1.01v2.83h.03ZM6.64,9.27h1.89v6.11h-1.89v-6.11Z"
        />
      </svg>
    </ShareNetwork>
    <ShareNetwork
      network="twitter"
      :url="`${socialShareUrl}`"
      :title="title"
      :description="description"
      style="display: inline-block !important"
      class="aw-ssi"
      @open="registerSocialShare(id, title)"
      v-if="share_buttons && share_buttons.twitter"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        height="23"
        class="ss-icon"
      >
        <circle class="ss-color" fill="#f0f0f0" cx="11" cy="11" r="11" />
        <path
          fill="none"
          stroke="#575757"
          stroke-width="0.8px"
          class="cls-1"
          d="M9.03,7.34h-2.28l5.93,7.45h2.28l-5.93-7.45Z"
        />
        <path fill="#575757" stroke-width="0" d="M14.96,6.9h-1.65l-6.97,8.2h1.65s6.97-8.2,6.97-8.2Z" />
      </svg>
    </ShareNetwork>
    <ShareNetwork
      network="facebook"
      :url="`${socialShareUrl}`"
      :title="title"
      :description="description"
      style="display: inline-block !important"
      class="aw-ssi"
      @open="registerSocialShare(id, title)"
      v-if="share_buttons && share_buttons.facebook"
    >
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
        height="23"
        class="ss-icon"
      >
        <circle fill="#f0f0f0" class="ss-color" stroke-width="0" cx="11" cy="11" r="11" />
        <path
          fill="#575757"
          stroke-width="0"
          d="M11.83,15.92v-4.42h1.61c.08-.6.17-1.19.26-1.8h-1.83s-.03-.06-.03-.08v-1.14c0-.35.19-.58.53-.66.07-.01.14-.01.21-.01h1.02s.08,0,.12-.01v-1.61s-.01,0,0,0c-.61,0-1.23-.01-1.84.01-.97.06-1.73.66-1.95,1.54-.04.17-.06.35-.06.53v1.44h-1.61v1.8h1.61v4.42h1.97-.01Z"
        />
      </svg>
    </ShareNetwork>
  </div>
</template>
<script lang="ts">
import { defineComponent, getCurrentInstance, PropType, ref } from "@vue/runtime-core"
import { GlobalDataKey } from "@/symbols"
import { setupSocialShare } from "@/composables/social-share"
import VueSocialSharing from "vue-social-sharing"
import injectStrict from "@/utils/injectStrict"
import { ShareButtons } from "@/models/ShareButtons"
import momentShareUrl from "@/services/moment-share-url"

export default defineComponent({
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    if (props.share_buttons) {
      var app = getCurrentInstance()
      if (!app) throw new Error("app cannot be null")
      if (app.appContext.components.ShareNetwork == undefined) app.appContext.app.use(VueSocialSharing)
    }
    const socialShareUrl = momentShareUrl(props.share_buttons?.landing_page_url ?? globalData.socialShareUrl, props.id)
    const { registerSocialShare } = setupSocialShare()
    return { registerSocialShare, socialShareUrl }
  }
})
</script>
<style>
.social-icon-container {
  display: flex;
  gap: 0px;
  justify-content: left;
  margin-bottom: 5px;
  margin-left: 0px;
}
</style>
