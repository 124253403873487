
import { defineComponent, getCurrentInstance, PropType, ref } from "@vue/runtime-core"
import { GlobalDataKey } from "@/symbols"
import { setupSocialShare } from "@/composables/social-share"
import VueSocialSharing from "vue-social-sharing"
import injectStrict from "@/utils/injectStrict"
import { ShareButtons } from "@/models/ShareButtons"
import momentShareUrl from "@/services/moment-share-url"

export default defineComponent({
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    if (props.share_buttons) {
      var app = getCurrentInstance()
      if (!app) throw new Error("app cannot be null")
      if (app.appContext.components.ShareNetwork == undefined) app.appContext.app.use(VueSocialSharing)
    }
    const socialShareUrl = momentShareUrl(props.share_buttons?.landing_page_url ?? globalData.socialShareUrl, props.id)
    const { registerSocialShare } = setupSocialShare()
    return { registerSocialShare, socialShareUrl }
  }
})
